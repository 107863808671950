import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import Cookies from "universal-cookie";
import {
  API_URL,
  BOOK_ACCESS,
  BOOK_SESSION_USER_IP_COOKIE,
  SIGN_IN_SESSION_COOKIE,
  STRIPE_PK_KEY,
} from "../../utils/constants";
import "../../App.css";
import "../../styles/auth.css";
import Header from "../../components/header";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [planType, setPlanType] = useState("");
  const [name, setName] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [messageColor, setMessageColor] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    const cookies = new Cookies();
    if (cookies.get(SIGN_IN_SESSION_COOKIE)) {
      window.location.href = "/";
    }
  }, []);

  const validateForm = () => {
    return email.length > 0 && password.length > 0 && planType.length > 0;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setShowMessage(false);
    setIsFormSubmitted(true);
    if (password.length < 6) {
      setMessageColor("red");
      setMessage("Password længde skal være mindst seks.");
      setIsFormSubmitted(false);
      setShowMessage(true);
      return;
    }
    let customerId;
    const cookies = new Cookies();
    await fetch(`${API_URL}/api/sign-up`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        email,
        password,
        name,
        bookToAccess: BOOK_ACCESS,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.body.error === "1") {
          setMessageColor("red");
          setMessage(responseJson.body.message);
          setIsFormSubmitted(false);
        } else {
          customerId = responseJson.body.customerId;
          setMessageColor("green");
          setMessage(responseJson.body.message);
          cookies.set(SIGN_IN_SESSION_COOKIE, email, {
            path: "/",
            maxAge: 31536000,
          });
          cookies.set(BOOK_SESSION_USER_IP_COOKIE, responseJson.body.userIp, {
            path: "/",
            maxAge: 31536000,
          });
        }
        setShowMessage(true);
      })
      .catch((error) => {
        console.error(error);
      });

    if (customerId) {
      await fetch(`${API_URL}/api/create-stripe-checkout`, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({
          customerId,
          email,
          planType,
          bookToAccess: BOOK_ACCESS,
        }),
      })
        .then(async (response) => response.json())
        .then(async (responseJson) => {
          console.log("STRIPE_PK_KEY", STRIPE_PK_KEY);
          const stripePromise = await loadStripe(STRIPE_PK_KEY || "");
          const stripe = await stripePromise;
          await stripe?.redirectToCheckout({
            sessionId: responseJson.body.session.id,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <>
      <Header />
      <div className="form-div customFormDiv">
        <form onSubmit={handleSubmit}>
          <h1 className="page-title">Køb adgang Statistik</h1>

          <div className="form-group">
            <label>Navn</label>
            <input
              type="name"
              className="form-control"
              placeholder="Skriv navn"
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={isFormSubmitted}
            />
          </div>

          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              placeholder="Skriv email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={isFormSubmitted}
            />
          </div>

          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              className="form-control"
              placeholder="Skriv password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              //maxLength="12"
              disabled={isFormSubmitted}
            />
          </div>

          <div className="form-group">
            <label>Vælg engangskøb</label>
            <div>
              <label
                data-tip="6 months 290 - DKK one time price"
                className="margin-right-20"
              >
                <input
                  type="radio"
                  name="size"
                  id="six_months_one_time"
                  value="six_months_one_time"
                  required
                  onChange={(e) => setPlanType(e.target.value)}
                  disabled={isFormSubmitted}
                />{" "}
                6 måneder 290,- DKK
              </label>
              <label
                data-tip="12 months 390 - DKK one time price"
                className="margin-right-20"
              >
                <input
                  type="radio"
                  name="size"
                  id="twelve_months_one_time"
                  value="twelve_months_one_time"
                  required
                  onChange={(e) => setPlanType(e.target.value)}
                  disabled={isFormSubmitted}
                />{" "}
                12 måneder 390,- DKK
              </label>
              <label data-tip="24 months 540 - DKK one time price">
                <input
                  type="radio"
                  name="size"
                  id="twenty_four_months_one_time"
                  value="twenty_four_months_one_time"
                  required
                  onChange={(e) => setPlanType(e.target.value)}
                  disabled={isFormSubmitted}
                />{" "}
                24 måneder 540,- DKK
              </label>
            </div>
            <label>Vælg abonnement</label>
            <div>
              <label data-tip="Monthly 59 DKK" className="margin-right-20">
                <input
                  type="radio"
                  name="size"
                  id="monthly_sixty"
                  value="monthly_sixty"
                  onChange={(e) => setPlanType(e.target.value)}
                  required
                  disabled={isFormSubmitted}
                />{" "}
                Månedsabonnement 59,- DKK fornyes automatisk
              </label>
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-secondary commonBtn"
            disabled={!validateForm() || isFormSubmitted}
          >
            Køb adgang nu
          </button>
          {showMessage ? (
            <p className="message" style={{ color: messageColor }}>
              {message}
            </p>
          ) : null}
          <p className="tnc">
            Ved tilmeldingen bekræfter du at have læst{" "}
            <a href="/term-service" target="_blank">
              abonnementsbetingelser
            </a>{" "}
            &{" "}
            <a href="/privacy-policy" target="_blank">
              privatlivspolitik
            </a>
            .
          </p>
          <p className="forgot-password text-right">
            <a href="/login" className="btn btn-secondary commonBtn">
              Allerede medlem? Login
            </a>
          </p>
          <p className="forgot-password text-right">
            <a href="/forgot-password" className="btn btn-secondary commonBtn">
              Glemt password?
            </a>
          </p>
          {/* <ReactTooltip /> */}
        </form>
      </div>
    </>
  );
};

export default SignUp;

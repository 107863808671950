export const API_URL = "https://tepedu.com/app";
//export const API_URL = "http://localhost:5001/app";
export const SIGN_IN_SESSION_COOKIE = "stat_book_loggedInUser";
export const BOOK_SESSION_USER_IP_COOKIE = "stat_book_loggedInUserIpAddress";
export const PREMIUM_POST = "Premium";
export const PRO_POST = "Pro";
export const FREE_POST = "Free";
export const USER_MONTHLY_SIXTY_PLAN_ID = "3";
export const USER_ONE_TIME_FIRST_PLAN_ID = "4";
export const USER_ONE_TIME_SECOND_PLAN_ID = "5";
export const USER_ONE_TIME_THIRD_PLAN_ID = "6";
export const USER_PREMIUM_PLAN_ID = "2";
export const USER_PRO_PLAN_ID = "1";
export const USER_NO_PLAN_ID = "0";
export const BOOK_ACCESS = "Statistik";
export const STRIPE_PK_KEY = process.env.REACT_APP_STRIPE_PK_KEY;
export const SITE_URL = "https://s.tepedu.com";
// export const SITE_URL = "http://localhost:3000";

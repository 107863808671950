import { useEffect, useState } from "react";
import "./tableOfContent.css";
import { useHeadsObserver } from "../../hooks";
import { SITE_URL } from "../../utils/constants";
import { useLocation } from "react-router-dom";

const getClassName = (level) => {
  switch (level) {
    case 2:
      return "head2";
    case 3:
      return "head3";
    case 4:
      return "head4";
    default:
      return null;
  }
};

function TableOfContent() {
  const [headings, setHeadings] = useState([]);
  const { activeId } = useHeadsObserver();
  const { pathname } = useLocation();

  useEffect(() => {
    const elements = Array.from(document.querySelectorAll("h2, h3, h4")).map(
      (elem) => ({
        id: elem.id,
        text: elem.innerText,
        level: Number(elem.nodeName.charAt(1)),
      })
    );
    setHeadings(elements);
  }, []);
  return (
    <>
      {headings.length > 0 ? (
        <nav className="toc">
          <p className="headTOC">Indhold</p>
          <ul>
            {headings.map((heading) => (
              <li key={heading.id} className={getClassName(heading.level)}>
                <a
                  href={`#${heading.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setTimeout(() => {
                      document.querySelector(`#${heading.id}`).scrollIntoView({
                        behavior: "smooth",
                      });
                    }, 1000);
                    window.location.href = `${SITE_URL}${pathname}#${heading.id}`;
                  }}
                  style={{
                    fontWeight: activeId === heading.id ? "bold" : "normal",
                  }}
                >
                  {heading.text}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      ) : null}
    </>
  );
}
export default TableOfContent;

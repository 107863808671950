import * as React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import "./popup.css";

const MONTH_NAMES = [
  "Januar",
  "Februar",
  "Marts",
  "April",
  "Maj",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "December",
];

const ProfilePopUp = ({
  userData,
  userPaymentData,
  userPaymentInvoice,
  userLatestPaymentInvoiceDate,
  isMonthlySubscription,
  isMonthlySubscriptionButCurrentZero,
  handleProfilePopupVisibility,
}: any) => {
  let dateFormat = new Date(userLatestPaymentInvoiceDate * 1000);

  const handleCloseClick = () => {
    handleProfilePopupVisibility(false);
  };

  return (
    // <Modal show={show} onHide={handleClose} className="profileModal">
    <Modal show={true} className="profileModal">
      <Modal.Header>
        <Modal.Title>Min konto</Modal.Title>
        <button
          onClick={handleCloseClick}
          type="button"
          className="btn btn-secondary commonBtn"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        {isMonthlySubscriptionButCurrentZero && (
          <p style={{ textAlign: "center", fontSize: "13px", color: "red" }}>
            Bemærk abonnementet er afmeldt. Der opkræves ikke yderligere
            betalinger.
          </p>
        )}
        <div className="marginWithBorder">
          <p className="detail-head">Bruger:</p>
          <div className="row font14">
            <div className="col-md-6">Navn: {userData.user_name}</div>
            <div className="col-md-6">Email: {userData.user_email}</div>
          </div>
        </div>
        {isMonthlySubscription && (
          <div className="marginWithBorder">
            <p className="detail-head">Plan:</p>
            <div className="row font14">
              <div className="col-md-6">
                {"Månedsabonnement fornyes automatisk"}
              </div>
              <div className="col-md-6">
                Sidst fornyet: {dateFormat.getDate()}.{" "}
                {MONTH_NAMES[dateFormat.getMonth()]}, {dateFormat.getFullYear()}
              </div>
            </div>
          </div>
        )}
        {!isMonthlySubscription && (
          <div className="marginWithBorder">
            <p className="detail-head">Plan:</p>
            <div className="row font14">
              <div className="col-md-6">
                {"Engangskøb fornyes ikke automatisk"}
              </div>
              <div className="col-md-6">
                Købsdato: {dateFormat.getDate()}.{" "}
                {MONTH_NAMES[dateFormat.getMonth()]}
                {", "}
                {dateFormat.getFullYear()}
              </div>
            </div>
          </div>
        )}
        {isMonthlySubscription && (
          <div className="marginWithBorder">
            <p className="detail-head">Kreditkort:</p>
            <div className="row font14">
              <div className="col-md-4">Brand: {userPaymentData.brand}</div>
              <div className="col-md-4">
                Sidste 4 cifre: {userPaymentData.last4}
              </div>
              <div className="col-md-4">
                Udløb: {userPaymentData.exp_month}, {userPaymentData.exp_year}
              </div>
            </div>
          </div>
        )}
        {isMonthlySubscription && (
          <div className="marginWithBorder">
            <p className="detail-head">Sidste faktura:</p>
            <div className="row font14">
              <div className="col-md-12">
                <p>
                  Klik{" "}
                  <a target="_blank" href={userPaymentInvoice}>
                    her
                  </a>{" "}
                  for at se sidste faktura
                </p>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ProfilePopUp;

export const CHAPTERS = [
  {
    id: 1,
    title: "1. Indledning",
    content: ``,
    image:
      "https://storage.googleapis.com/tepedu/images/toiletpaper.jpg",
    excerpt: "Hvad er statistik, og hvor bruges det?",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Free, Statistik",
  },

  {
    id: 2,
    title: "2. Datasæt og data",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1483736762161-1d107f3c78e1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDI0fHxkYXRhfGVufDB8fHx8MTYyNTc3MzkyOQ&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Hvilke typer af data findes der?",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Pro, Statistik",
  },

  {
    id: 3,
    title: "3. Deskriptiv statistik",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1589187775328-882e91b3db4f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDJ8fHN1cnZleXxlbnwwfHx8fDE2MjU3NzQ2OTQ&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Hvordan kan man beskrive data?",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Pro, Statistik",
  },

  {
    id: 4,
    title: "4. Normalfordelingen",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1569622680756-7afaa5666a1e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDd8fGJlbGx8ZW58MHx8fHwxNjI1OTM4MTUx&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Er nedbør normalfordelt?",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Pro, Statistik",
  },
  {
    id: 5,
    title: "5. Konfidensintervaller",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1600073881176-0eeb400d2de9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDR8fDk1fGVufDB8fHx8MTYyNjIyMTA0MQ&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Hvor sikre er vi på vores estimat?",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Pro, Statistik",
  },
  {
    id: 6,
    title: "6. Hypotesetest middelværdi",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1508014924734-d75124b0f402?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDI1fHx0ZXN0fGVufDB8fHx8MTYyNjI1NDY0MA&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Hvordan opstiller vi et hypotesetest?",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Pro, Statistik",
  },
  {
    id: 7,
    title: "7. 1 Andel",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1602630209855-dceac223adfe?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDJ8fHNsaWNlJTIwb2YlMjBjYWtlfGVufDB8fHx8MTYyNjI2NzE2OA&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Hvor stor er andelen af mobilepay brugere?",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Pro, Statistik",
  },
   {
    id: 8,
    title: "8. 2 Andele",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1578776342930-c1119d867534?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDd8fHR3b3xlbnwwfHx8fDE2MjYyOTQ5MzY&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Er andelen af mænd større på CBS end på KU?",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Pro, Statistik",
  },
   {
    id: 9,
    title: "9. 2 Middelværdier",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1612170815303-2bce69955f02?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDE3fHx0d2luc3xlbnwwfHx8fDE2MjYzMDg3NzI&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Har unge og ældre samme indtægt?",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Pro, Statistik",
  },
   {
    id: 10,
    title: "10. Korrelation",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1499194306280-462b274e4743?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDIzNHx8aWNlY3JlYW18ZW58MHx8fHwxNjI2MTI2NjQ2&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Er der sammenhæng mellem temperatur og issalg?",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Pro, Statistik",
  },
   {
    id: 11,
    title: "11. Lineær regressionsanalyse",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1567206563064-6f60f40a2b57?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDV8fGljZSUyMGNyZWFtfGVufDB8fHx8MTYyNjU0NDE3OA&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Hvor mange is sælger man, når temperaturen er 27 grader?",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Pro, Statistik",
  },
   {
    id: 12,
    title: "12. Binomialfordelingen",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1553575141-67b60994cfd3?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDR8fHR3b3xlbnwwfHx8fDE2MjYxNzU0NzA&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Hvad er sandsynligheden for 4 ud af 100 får COVID-19?",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Pro, Statistik",
  },
   {
    id: 13,
    title: "13. Den hypergeometriske fordeling",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1534705867302-2a41394d2a3b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDF8fE0lMjBhbmQlMjBNfGVufDB8fHx8MTYyNjE4ODUwMw&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Hvad er sandsynligheden for at trække 5 gule M&M's fra skålen?",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Pro, Statistik",
  },
   {
    id: 14,
    title: "14. Poisson fordelingen",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1596383765797-8e10e88d1590?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDF8fGZsYXQlMjB0aXJlfGVufDB8fHx8MTYyNjIxMzkzMA&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Hvor mange punkteringer indtræffer på 1000 km?",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Pro, Statistik",
  },
   {
    id: 15,
    title: "15. Stikprøveteori",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1616587894998-eb4d3007bbb9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDQwfHxpbnRlcnZpZXd8ZW58MHx8fHwxNjI2MjE2ODM0&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Hvordan skal stikprøven udvælges?",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Pro, Statistik",
  },
   {
    id: 16,
    title: "16. Sandsynligheder",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1476370648495-3533f64427a2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDE5fHxkZWF0aHxlbnwwfHx8fDE2MjYzNTE3Njc&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Hvad er sandsynligheden for at blive dødsdømt?",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Pro, Statistik",
  },
   {
    id: 17,
    title: "17. Chi i anden tests",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1600493572024-0bd944b3f0b0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDIyfHx4fGVufDB8fHx8MTYyNjM1NTgzMQ&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Er der sammenhæng mellem vandskader og hvor man bor?",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Pro, Statistik",
  },
   {
    id: 18,
    title: "18. ANOVA",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1569025690938-a00729c9e1f9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDl8fGZpbmFuY2V8ZW58MHx8fHwxNjI2MzkwNzc2&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Giver danske bankaktier samme afkast?",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Pro, Statistik",
  },
   {
    id: 19,
    title: "19. R",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1555949963-ff9fe0c870eb?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDN8fHNvZnR3YXJlfGVufDB8fHx8MTYyNjM5ODAyOA&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Er R et godt programmeringssprog?",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Pro, Statistik",
  },
   {
    id: 20,
    title: "20. Faktoranalyse",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1606166325695-ce4d64e3195f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDN8fGNvbXB1dGV8ZW58MHx8fHwxNjI2Mzk4Mzg2&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Hvilke grupper af variable varierer sammen?",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Pro, Statistik",
  },
   {
    id: 21,
    title: "21. Klyngeanalyse",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1566843017973-b3b1c1feb794?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDE5fHxjbHVzdGVyfGVufDB8fHx8MTYyNjQzNTc5Ng&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Hvilke respondenter ligner hinanden?",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Pro, Statistik",
  },
   {
    id: 22,
    title: "22. Tidsrækker og ARIMA",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1423666523292-b458da343f6a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDN8fHN0b2NrJTIwcHJpY2VzfGVufDB8fHx8MTYyNjQ2Mjk3MQ&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Hvad skal Apple aktien koste imorgen?",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Pro, Statistik",
  },
   {
    id: 23,
    title: "23. Podcasts",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1485579149621-3123dd979885?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDN8fHBvZGNhc3R8ZW58MHx8fHwxNjI1NzM2Mjc4&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Her kan du høre en podcast-serie, omkring statistik",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Pro, Statistik",
  },
   {
    id: 24,
    title: "24. Materialer",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1562654501-a0ccc0fc3fb1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDIzfHxwYXBlcnN8ZW58MHx8fHwxNjI2NjEwNTQ1&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Her findes datasæt, skabeloner og mindmap",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Pro, Statistik",
  },
   {
    id: 25,
    title: "25. Ordliste",
    content: ``,
    image:
      "https://images.unsplash.com/reserve/uZYSV4nuQeyq64azfVIn_15130980706_64134efc6e_o.jpg?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDMwfHx3b3Jkc3xlbnwwfHx8fDE2MjY2NDM5OTM&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Her er en oversigt over begreber med kort forklaring",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Pro, Statistik",
  },
  {
    id: 26,
    title: "26. Eksamensvideoer Finansøkonom",
    content: ``,
    image:
      "https://storage.googleapis.com/tepedu/images/eksamensvideoer.jpg",
    excerpt: "Eksamensplaylists Finansøkonom Statistik eksamen",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Pro, Statistik",
  },
  {
    id: 27,
    title: "27. Eksamensvideoer Akademiuddannelsen",
    content: ``,
    image:
      "https://storage.googleapis.com/tepedu/images/eksamensvideoer2.jpg",
    excerpt: "Eksamensplaylists AU Statistik eksamen",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Pro, Statistik",
  },
  {
    id: 28,
    title: "28. Eksamensopgaver Kvantitative metoder",
    content: ``,
    image:
      "https://storage.googleapis.com/tepedu/images/chapter28_frontimage.jpg",
    excerpt: "Eksamensopgaver Finansbachelor",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Pro, Statistik",
  },

   {
    id: 29,
    title: "29. Excel",
    content: ``,
    image:
      "https://storage.googleapis.com/tepedu/images/excel.jpg",
    excerpt: "Excel pivottabeller og specielle funktioner",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Free, Statistik",
  },

   {
    id: 30,
    title: "30. Google Analyse",
    content: ``,
    image:
      "https://storage.googleapis.com/tepedu/images/google2.jpg",
    excerpt: "Spørgeskemaer med Google Analyse",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Free, Statistik",
  },

   {
    id: 31,
    title: "31. Jura og etik",
    content: ``,
    image:
      "https://storage.googleapis.com/tepedu/images/databreach.jpg",
    excerpt: "juridiske og etiske aspekter i forbindelse med dataindsamling, analyse og formidling",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Pro, Statistik",
  },

  {
    id: 32,
    title: "32. Metode",
    content: ``,
    image:
      "https://storage.googleapis.com/tepedu/images/method.jpg",
    excerpt: "Metode til opgaveskrivning",
    authorName: "Thomas Petersen",
    authorImage:
      "//www.gravatar.com/avatar/514f3f5c0fb07b7d3d48811220ecaa75?s=250&amp;d=mm&amp;r=x",
    tags: "Free, Statistik",
  },
  
  
];

import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import UserProvider from "./context/UserContext";
import PageNotFound from "./pages/404/notFound";
import ForgotPassword from "./pages/forgot-password/forgotPassword";
import Home from "./pages/home/home";
import Login from "./pages/login/login";
import Privacy from "./pages/privacy-policy/privacy";
import SignUp from "./pages/signup/signup";
import Terms from "./pages/tnc/tnc";
import View from "./pages/kapitel/kapitel";

const App = () => {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/kapitel/:id" element={<View />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/term-service" element={<Terms />} />
          <Route path="/*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </UserProvider>
  );
};

export default App;

import Header from "../../components/header";
import "../../App.css";

const Terms = () => {
  return (
    <>
      <Header />
      <main className="mainPage">
        <div className="container">
          <article className="content">
            <h1 className="content-title">Salgsbetingelser</h1>
            <section className="content-body load-external-scripts">
              <p>Vigtigt fraskrivelse af 14 dages fortrydelsesret</p>
              <p>
                Bemærk ved køb af digitale produkter på tepedu.dk, fraskriver
                du, som køber dig, fra den normale 14 dages fortrydelsesret. Det
                betyder du ikke kan fortryde købet, produktet leveres
                umiddelbart efter betalingen.
              </p>
              <p>
                Har du købt abonnementsadgang til bogen på månedsbasis, kan du
                opsige månedsabonnementet uden binding til udløbet af seneste
                betalingscyklus. Det betyder du ved opsigelse ikke modtager
                yderligere opkrævninger.
              </p>
              <p>
                Bogen er kun til personligt brug. Alle rettigheder forbeholdes.
                Fotografisk eller anden gengivelse af eller kopiering eller
                anden udnyttelse, er uden forfatterens skriftlige samtykke
                forbudt ifølge dansk lov om ophavsret.
              </p>
            </section>
          </article>
        </div>
      </main>
    </>
  );
};

export default Terms;

import * as React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import "./popup.css";
import { Elements } from "@stripe/react-stripe-js";
import { API_URL, STRIPE_PK_KEY } from "../../utils/constants";
import { loadStripe } from "@stripe/stripe-js";
import CardSetupForm from "../CardSetupForm";

const UpdateCardPopUp = ({
  handleUpdateCardPopupVisibility,
  customerId,
}: any) => {
  const stripePromise = loadStripe(STRIPE_PK_KEY || "");

  const handleCloseClick = () => {
    handleUpdateCardPopupVisibility(false);
  };

  return (
    <Modal show={true} className="profileModal">
      <Modal.Header>
        <Modal.Title>Skift kreditkort</Modal.Title>
        <button
          onClick={handleCloseClick}
          type="button"
          className="btn btn-secondary commonBtn"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <Elements stripe={stripePromise}>
          <CardSetupForm customerId={customerId} />
        </Elements>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateCardPopUp;

import Header from "../../components/header";
import "../../App.css";
import { Container } from "react-bootstrap";

const PageNotFound = () => {
  return (
    <>
      <Header />
      <main className="mainPage">
        <Container>Not Found</Container>
      </main>
    </>
  );
};

export default PageNotFound;

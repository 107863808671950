import Header from "../../components/header";
import "../../App.css";

const Privacy = () => {
  return (
    <>
    <Header/>
      <main className="mainPage">
        <div className="container">
          <article className="content">
            <h1 className="content-title">Privatlivspolitik</h1>
            <section className="content-body load-external-scripts">
              <p>
                Dit privatliv er vigtigt for os. Vi håndterer oplysninger fra
                dig, som kunde, på en fortrolig måde. Vi sælger ikke personlige
                oplysninger til nogen anden organisation.
              </p>
              <p>
                Eventuelle personlige oplysninger indsamlet vil blive brugt til
                ordrebehandling, adressere henvendelser, produkt- eller
                statusopdateringer, nyhedsbreve, og/eller anden gentagende
                korrespondance (hvis kunden har valgt det).
              </p>
              <p>
                På ethvert tidspunkt kan du, som kunde, fravælge email
                henvendelser
              </p>
              <p>
                Nogle måder, som vi måske skal bruge til at dele dine
                oplysninger med en tredjepart, er anført nedenfor:
              </p>
              <p>
                - Med omhyggeligt udvalgte virksomheder, der tilbyder
                supporttjenester til os. Disse omfatter betalings- og
                kreditkorthåndteringsvirksomheder, enhver platform, hvor vores
                teknologi er hostet eller oprettet.
              </p>
              <p>
                - Med dit samtykke og som nødvendigt for at gennemføre de
                transaktioner, der er anmodet om af dig.
              </p>
              <p>- Hvis det kræves i henhold til loven.</p>
              <p>
                - Om nødvendigt for at beskytte vores intellektuelle rettigheder
                og / eller ejendom.
              </p>
              <p>
                - I tilfælde af overdragelse af forretningsmæssige aktiver, kan
                der gives oplysninger til parter, der er involveret i
                forhandling og overførsel.
              </p>
              <p>
                - Eventuelle kommentarer på vores hjemmeside eller oplysninger
                indsendt af dig vil være synlige for andre, der besøger
                webstedet.
              </p>
              <p>
                Derudover kan vi dele anonymiseret information og/eller
                aggregeret information for at hjælpe os med at levere en bedre
                oplevelse til dig. Disse oplysninger identificerer dig ikke
                direkte. Ved at vælge at bruge vores websted og/eller teknologi
                accepterer du privatlivspolitikken som beskrevet ovenfor.
              </p>
            </section>
          </article>
        </div>
      </main>
    </>
  );
};

export default Privacy;

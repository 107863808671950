import * as React from "react";
import { useContext } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "../App.css";
import { UserContext } from "../context/UserContext";
import { CHAPTERS } from "../data/chapters";
import { API_URL, BOOK_ACCESS } from "../utils/constants";
import CancelSubscriptionPopUp from "./popups/cancelSubscriptionPopup";
import ProfilePopUp from "./popups/profile-popup";
import UpdateCardPopUp from "./popups/updateCardPopup";
import FilterResults from "react-filter-search";
import { Spinner } from "react-bootstrap";

declare global {
  interface Window {
    find: any;
  }
}

const Header = () => {
  const { userData, userDataLoaded, userLogout } = useContext(UserContext);
  const [userPaymentData, setUserPaymentData] = React.useState({});
  const [userPaymentInvoice, setUserPaymentInvoice] = React.useState("");
  const [userLatestPaymentInvoiceDate, setUserLatestPaymentInvoiceDate] =
    React.useState("");
  const [showProfilePopup, setShowProfilePopup] = React.useState(false);
  const [showCancelSubPopup, setShowCancelSubPopup] = React.useState(false);
  const [showCardUpdatePopup, setShowCardUpdatePopup] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [isMonthlySubscription, setIsMonthlySubscription] =
    React.useState(false);
  const [
    isMonthlySubscriptionButCurrentZero,
    setIsMonthlySubscriptionButCurrentZero,
  ] = React.useState(false);
  const [searchInChapterTotalResults, setSearchInChapterTotalResults] =
    React.useState(0);
  const [searchInChapterResults, setSearchInChapterResults] =
    React.useState<{ text: string; position: number }[]>();
  const [isSearchHidden, setIsSearchHidden] = React.useState(true);

  let postPath = "";
  if (typeof window !== "undefined") {
    postPath = window.location.pathname;
  }
  React.useEffect(() => {
    if (userData.user_id) {
      if (userData.plan_id == 0 && userData.user_subscription_start) {
        setIsMonthlySubscription(true);
        setIsMonthlySubscriptionButCurrentZero(true);
      } else if (userData.plan_id == 1) {
        setIsMonthlySubscription(true);
      } else if (userData.plan_id == 2) {
        setIsMonthlySubscription(true);
      } else if (userData.plan_id == 3) {
        setIsMonthlySubscription(true);
      }
      if (userData.stripe_id) {
        getUserPaymentMethod();
        getUserInvoices();
      }
    }
  }, [userData]);
  const getUserPaymentMethod = async () => {
    await fetch(`${API_URL}/api/customer-payment-method`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        customerStripeId: userData.stripe_id,
        bookToAccess: BOOK_ACCESS,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setUserPaymentData(responseJson.body.paymentMethods.data[0].card);
      });
  };
  const getUserInvoices = async () => {
    await fetch(`${API_URL}/api/customer-invoices`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        customerStripeId: userData.stripe_id,
        bookToAccess: BOOK_ACCESS,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setUserPaymentInvoice(
          responseJson.body.invoices.data[0].hosted_invoice_url
        );
        setUserLatestPaymentInvoiceDate(
          responseJson.body.invoices.data[0].created
        );
      });
  };

  const handleProfilePopupVisibility = (isOpen: boolean) => {
    setShowProfilePopup(isOpen);
  };

  const handleCancelSubPopupVisibility = (isOpen: boolean) => {
    setShowCancelSubPopup(isOpen);
  };

  const handleUpdateCardPopupVisibility = (isOpen: boolean) => {
    setShowCardUpdatePopup(isOpen);
  };

  const handleMainSearchChange = (event: any) => {
    const { value } = event.target;
    setValue(value);
    removeHighlightedText();
    if (postPath !== "/" && value.length >= 3) {
      if (value) {
        let searchExp = new RegExp(value, "gi");
        let match;
        let count = 0;
        let maxAdjacentLength = 20;
        const matches: { text: string; position: number; id: string }[] = [];
        const textElement = document.querySelector(
          ".mainPage > .container > .row > .col-md-9"
        );

        if (textElement) {
          const text = textElement.innerHTML;
          const textContent = textElement.textContent;
          if (text && textContent) {
            const highlightedText = text.replace(
              searchExp,
              (match) =>
                `<span style="background-color: yellow" class="yellowHighlightedText">${match}</span>`
            );

            textElement.innerHTML = highlightedText;

            // Count matches and positions
            searchExp.lastIndex = 0;
            while ((match = searchExp.exec(textContent)) !== null) {
              count++;
              var start = Math.max(0, match.index - maxAdjacentLength);
              var end = Math.min(
                textContent.length,
                match.index + value.length + maxAdjacentLength
              );
              var adjacentText = textContent.substring(start, end);
              matches.push({
                text: adjacentText,
                position: match.index,
                id: `selectedText_${match.index}`,
              });
            }

            // Get all elements with the specified class name
            var elements = document.getElementsByClassName(
              "yellowHighlightedText"
            );

            // Iterate through the elements
            for (var i = 0; i < elements.length; i++) {
              // Assign a unique id to each element
              elements[i].id = matches[i].id;
            }

            setSearchInChapterTotalResults(count);
            setSearchInChapterResults(matches);
          }
        }
      } else {
        alert("Please enter a search term.");
      }
    }
  };

  const handleMainSearchFocus = (event: any) => {
    setTimeout(() => {
      setValue("");
    }, 300);
  };

  const removeHighlightedText = () => {
    // Get all elements with the class "yellowHighlightedText"
    var elements = document.querySelectorAll(".yellowHighlightedText");

    // Loop through each element
    elements.forEach(function (element) {
      // Create a document fragment to hold the child nodes temporarily
      var fragment = document.createDocumentFragment();

      // Move all child nodes of the element into the fragment
      while (element.firstChild) {
        fragment.appendChild(element.firstChild);
      }

      // Replace the element with its content (text nodes)
      if (element.parentNode) {
        element.parentNode.replaceChild(fragment, element);
      }
    });
  };

  React.useEffect(() => {
    async function fetchAllHtml() {
      setIsSearchHidden(true);
      const response1 = await fetch(`/chapters/1.html`);
      const html1 = await response1.text();

      const response2 = await fetch(`/chapters/2.html`);
      const html2 = await response2.text();

      const response3 = await fetch(`/chapters/3.html`);
      const html3 = await response3.text();

      const response4 = await fetch(`/chapters/4.html`);
      const html4 = await response4.text();

      const response5 = await fetch(`/chapters/5.html`);
      const html5 = await response5.text();

      const response6 = await fetch(`/chapters/6.html`);
      const html6 = await response6.text();

      const response7 = await fetch(`/chapters/7.html`);
      const html7 = await response7.text();

      const response8 = await fetch(`/chapters/8.html`);
      const html8 = await response8.text();

      const response9 = await fetch(`/chapters/9.html`);
      const html9 = await response9.text();

      const response10 = await fetch(`/chapters/10.html`);
      const html10 = await response10.text();

      const response11 = await fetch(`/chapters/11.html`);
      const html11 = await response11.text();

      const response12 = await fetch(`/chapters/12.html`);
      const html12 = await response12.text();

      const response13 = await fetch(`/chapters/13.html`);
      const html13 = await response13.text();

      const response14 = await fetch(`/chapters/14.html`);
      const html14 = await response14.text();

      const response15 = await fetch(`/chapters/15.html`);
      const html15 = await response15.text();

      const response16 = await fetch(`/chapters/16.html`);
      const html16 = await response16.text();

      const response17 = await fetch(`/chapters/17.html`);
      const html17 = await response17.text();

      const response18 = await fetch(`/chapters/18.html`);
      const html18 = await response18.text();

      const response19 = await fetch(`/chapters/19.html`);
      const html19 = await response19.text();

      const response20 = await fetch(`/chapters/20.html`);
      const html20 = await response20.text();

      const response21 = await fetch(`/chapters/21.html`);
      const html21 = await response21.text();

      const response22 = await fetch(`/chapters/22.html`);
      const html22 = await response22.text();

      const response23 = await fetch(`/chapters/23.html`);
      const html23 = await response23.text();

      const response24 = await fetch(`/chapters/24.html`);
      const html24 = await response24.text();

      const response25 = await fetch(`/chapters/25.html`);
      const html25 = await response25.text();

      const response26 = await fetch(`/chapters/26.html`);
      const html26 = await response26.text();

      const response27 = await fetch(`/chapters/27.html`);
      const html27 = await response27.text();

      const response28 = await fetch(`/chapters/28.html`);
      const html28 = await response28.text();

      const response29 = await fetch(`/chapters/29.html`);
      const html29 = await response29.text();

      const response30 = await fetch(`/chapters/30.html`);
      const html30 = await response30.text();

      const response31 = await fetch(`/chapters/31.html`);
      const html31 = await response31.text();

      const response32 = await fetch(`/chapters/32.html`);
      const html32 = await response32.text();

      CHAPTERS[0].content = html1;
      CHAPTERS[1].content = html2;
      CHAPTERS[2].content = html3;
      CHAPTERS[3].content = html4;
      CHAPTERS[4].content = html5;
      CHAPTERS[5].content = html6;
      CHAPTERS[6].content = html7;
      CHAPTERS[7].content = html8;
      CHAPTERS[8].content = html9;
      CHAPTERS[9].content = html10;
      CHAPTERS[10].content = html11;
      CHAPTERS[11].content = html12;
      CHAPTERS[12].content = html13;
      CHAPTERS[13].content = html14;
      CHAPTERS[14].content = html15;
      CHAPTERS[15].content = html16;
      CHAPTERS[16].content = html17;
      CHAPTERS[17].content = html18;
      CHAPTERS[18].content = html19;
      CHAPTERS[19].content = html20;
      CHAPTERS[20].content = html21;
      CHAPTERS[21].content = html22;
      CHAPTERS[22].content = html23;
      CHAPTERS[23].content = html24;
      CHAPTERS[24].content = html25;
      CHAPTERS[25].content = html26;
      CHAPTERS[26].content = html27;
      CHAPTERS[27].content = html28;
      CHAPTERS[28].content = html29;
      CHAPTERS[29].content = html30;
      CHAPTERS[30].content = html31;
      CHAPTERS[31].content = html32;

      setIsSearchHidden(false);
    }
    fetchAllHtml();
  }, []);

  return (
    <>
      <Navbar expand="lg" className="header">
        <Container>
          <Navbar.Brand href="/">
            <img src="/tepedu.svg" className="logoImg" width="100" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavDropdown
                title="Indhold"
                id="basic-nav-dropdown"
                className="chapterListBtn"
              >
                {CHAPTERS.map((chapter) => {
                  return (
                    <NavDropdown.Item href={`/kapitel/${chapter.id}`}>
                      {chapter.title}
                    </NavDropdown.Item>
                  );
                })}
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse className="justify-content-center">
            {/* {postPath === "/" && ( */}
            <div>
              <div className="search-container-main">
                <input
                  className="search-main"
                  id="searchleftmain"
                  type="search test"
                  name="q"
                  placeholder="Søg"
                  value={value}
                  onChange={handleMainSearchChange}
                  onBlur={(e) => handleMainSearchFocus(e)}
                />
                <label
                  className="button-main searchbutton-main"
                  htmlFor="searchleftmain"
                >
                  <span className="mglass-main">&#9906;</span>
                </label>
              </div>
              <FilterResults
                value={value}
                data={CHAPTERS}
                renderResults={(results: any) => {
                  return (
                    <div
                      className={`searchFilter ${value ? "" : "filterHide"}`}
                    >
                      <>
                        {isSearchHidden ? (
                          <Spinner animation="border" />
                        ) : (
                          <>
                            <p>
                              {results.length + searchInChapterTotalResults}{" "}
                              resultater
                            </p>
                            {searchInChapterTotalResults !== 0 && (
                              <div className="withinChapterResults">
                                {searchInChapterResults &&
                                  searchInChapterResults.map((el: any) => (
                                    <div className="withinChapterResult">
                                      <p
                                        onClick={() => {
                                          // Get the element by its ID
                                          var element = document.getElementById(
                                            el.id
                                          );
                                          // Set the background color
                                          if (element) {
                                            element.style.backgroundColor =
                                              "orange";
                                            element.scrollIntoView({
                                              behavior: "smooth",
                                            });
                                          }
                                        }}
                                      >
                                        {el.text}
                                      </p>
                                    </div>
                                  ))}
                              </div>
                            )}

                            {results.map((el: any) => (
                              <div>
                                <a href={"/kapitel/" + el.id}>{el.title}</a>
                              </div>
                            ))}
                          </>
                        )}
                      </>
                    </div>
                  );
                }}
              />
            </div>
            {/* )} */}
            {/* {postPath !== "/" && (
              <div>
                <div className="search-container-main">
                  <input
                    className="search-main"
                    id="searchleft"
                    type="search test"
                    name="q"
                    onKeyUp={(e) => serachInPage(e)}
                    placeholder="Søg i kapitlet"
                  />
                  <label
                    className="button-main searchbutton-main"
                    htmlFor="searchleft"
                  >
                    <span className="mglass-main">&#9906;</span>
                  </label>
                </div>
              </div>
            )} */}
          </Navbar.Collapse>
          <Navbar.Collapse className="justify-content-end">
            {userDataLoaded && !userData.user_id && (
              <Navbar.Text>
                <a className="loginBtn" href="/login">
                  Login
                </a>
              </Navbar.Text>
            )}
            {userDataLoaded && userData.user_id && (
              <NavDropdown
                title={userData.user_email}
                id="basic-nav-dropdown"
                className="chapterListBtn"
              >
                <NavDropdown.Item
                  onClick={() => handleProfilePopupVisibility(true)}
                >
                  Min konto
                </NavDropdown.Item>
                {isMonthlySubscription && (
                  <>
                    {!isMonthlySubscriptionButCurrentZero && (
                      <NavDropdown.Item
                        onClick={() => handleCancelSubPopupVisibility(true)}
                      >
                        Afmeld abonnement
                      </NavDropdown.Item>
                    )}
                    <NavDropdown.Item
                      onClick={() => handleUpdateCardPopupVisibility(true)}
                    >
                      Skift Kreditkort
                    </NavDropdown.Item>
                  </>
                )}
                <NavDropdown.Item href="#" onClick={userLogout}>
                  Log Out
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {showProfilePopup && (
        <ProfilePopUp
          userData={userData}
          userPaymentData={userPaymentData}
          userPaymentInvoice={userPaymentInvoice}
          userLatestPaymentInvoiceDate={userLatestPaymentInvoiceDate}
          isMonthlySubscription={isMonthlySubscription}
          isMonthlySubscriptionButCurrentZero={
            isMonthlySubscriptionButCurrentZero
          }
          handleProfilePopupVisibility={handleProfilePopupVisibility}
        />
      )}
      {showCancelSubPopup && (
        <CancelSubscriptionPopUp
          handleCancelSubPopupVisibility={handleCancelSubPopupVisibility}
          userStripeId={userData.stripe_id}
        />
      )}
      {showCardUpdatePopup && (
        <UpdateCardPopUp
          handleUpdateCardPopupVisibility={handleUpdateCardPopupVisibility}
          customerId={userData.stripe_id}
        />
      )}
    </>
  );
};

export default Header;

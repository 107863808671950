import React, { useEffect, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

import CardSection from "./CardSection";
import { API_URL, BOOK_ACCESS } from "../utils/constants";

const CardSetupForm = ({ customerId }: any) => {
  const stripe = useStripe();
  const elements = useElements();

  const [userSecret, setUserSecret] = useState("");
  const [errorMessage, setErrorMessage] = useState<any>("");
  const [successMessage, setSuccessMessage] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);

  // const userStripeId = props.customerId;

  useEffect(() => {
    fetch(`${API_URL}/api/setup-intent`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({ customerId, bookToAccess: BOOK_ACCESS }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setUserSecret(responseJson.body.intent.client_secret);
      });
  }, []);

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setErrorMessage("");
    setBtnDisable(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const cardElement = elements.getElement(CardElement);
    let result;
    if (cardElement) {
      result = await stripe.confirmCardSetup(userSecret, {
        payment_method: {
          card: cardElement,
        },
      });
    }

    if (result && result.error) {
      //alert();
      setErrorMessage(result.error.message);
      setBtnDisable(false);
    } else {
      // The setup has succeeded. Display a success message and send
      // result.setupIntent.payment_method to your server to save the
      // card to a Customer
      const pm = result?.setupIntent.payment_method;
      const us = customerId;
      await fetch(`${API_URL}/api/update-payment-method`, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ us, pm, bookToAccess: BOOK_ACCESS }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setSuccessMessage("Your card added successfully, reloading.");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <CardSection />
        <button
          disabled={btnDisable}
          className="btn btn-secondary commonBtn marginTop_five"
        >
          Gem kreditkort
        </button>
      </form>
      <p className="error">{errorMessage}</p>
      <p className="success">{successMessage}</p>
    </div>
  );
};

export default CardSetupForm;

import * as React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import "./popup.css";
import { Elements } from "@stripe/react-stripe-js";
import { API_URL } from "../../utils/constants";
import { loadStripe } from "@stripe/stripe-js";
import CardSetupForm from "../CardSetupForm";

const HelpVideoPopUp = ({ handlePopupVisibility, videoSrc }: any) => {
  const handleCloseClick = () => {
    handlePopupVisibility(false);
  };
  return (
    <Modal show={true} className="profileModal helpVideoModal">
      <Modal.Body className="ratio ratio-16x9">
        <video autoPlay src={videoSrc} id="helpVideo" />
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
          onClick={() => handleCloseClick()}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default HelpVideoPopUp;

import React, { useEffect, useState, useContext } from "react";
import Cookies from "universal-cookie";
import {
  API_URL,
  BOOK_ACCESS,
  SIGN_IN_SESSION_COOKIE,
  BOOK_SESSION_USER_IP_COOKIE,
} from "../../utils/constants";
import "../../App.css";
import "../../styles/auth.css";
import Header from "../../components/header";
import { UserContext } from "../../context/UserContext";

const Login = () => {
  const { userData, userDataLoaded } = useContext(UserContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [messageColor, setMessageColor] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    if (userData.user_id) {
      window.location.href = "/";
    }
  }, [userData, userDataLoaded]);

  const validateForm = () => {
    return email.length > 0 && password.length > 0;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setShowMessage(false);
    setIsFormSubmitted(true);
    if (password.length < 6) {
      setMessageColor("red");
      setMessage("Password længde skal mindst være seks.");
      setShowMessage(true);
      setIsFormSubmitted(false);
      return;
    }
    let planId;
    const cookies = new Cookies();
    await fetch(`${API_URL}/api/login`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        email: email,
        password: password,
        bookToAccess: BOOK_ACCESS,
      }),
    })
      .then(async (response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.body.error == "1") {
          setMessageColor("red");
          setMessage(responseJson.body.message);
          setIsFormSubmitted(false);
        } else {
          planId = responseJson.body.planId;
          setMessageColor("green");
          setMessage("Du er nu logget ind");
          await cookies.set(SIGN_IN_SESSION_COOKIE, email, {
            path: "/",
            maxAge: 31536000,
          });
          await cookies.set(
            BOOK_SESSION_USER_IP_COOKIE,
            responseJson.body.userIp,
            {
              path: "/",
              maxAge: 31536000,
            }
          );
        }
        setShowMessage(true);
      })
      .catch((error) => {
        console.error(error);
      });
    if (cookies.get(SIGN_IN_SESSION_COOKIE)) {
      window.location.href = "/";
    }
  };

  return (
    <>
      {userDataLoaded && !userData.user_id && (
        <>
          <Header />
          <div className="form-div customFormDiv mainPage">
            <form onSubmit={handleSubmit} autoComplete="on">
              <h1 className="page-title">Adgang Statistik</h1>

              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Skriv email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={isFormSubmitted}
                  autoComplete="on"
                />
              </div>

              <div className="form-group">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Skriv password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="on"
                  disabled={isFormSubmitted}
                />
              </div>

              <button
                type="submit"
                disabled={!validateForm() || isFormSubmitted}
                className="btn btn-secondary commonBtn marginTop_five"
              >
                Log ind
              </button>
              {showMessage ? (
                <p className="message" style={{ color: messageColor }}>
                  {message}
                </p>
              ) : null}
              <p className="forgot-password text-right">
                <a className="btn btn-secondary commonBtn" href="/sign-up">
                  Ny? Køb adgang
                </a>
              </p>
              <p className="forgot-password text-right">
                <a
                  className="btn btn-secondary commonBtn"
                  href="/forgot-password"
                >
                  Glemt Password?
                </a>
              </p>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default Login;

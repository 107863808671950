import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../components/header";
import { UserContext } from "../../context/UserContext";
import "../../App.css";
import "./home.css";
import { CHAPTERS } from "../../data/chapters";
import Footer from "../../components/footer";
import { STRIPE_PK_KEY } from "../../utils/constants";

const Home = () => {
  const { todos, userData } = useContext(UserContext);
  return (
    <>
      <Header />
      <Container className="mainPage">
        <Row>
          {CHAPTERS.map((chapter) => {
            return (
              <Col md={4}>
                <a href={`/kapitel/${chapter.id}`} className="chapterLink">
                  <Card className="chapterCard">
                    {/* <Card.Img
                      variant="top"
                      src={chapter.image}
                      className="chapterImg"
                    /> */}
                    <div
                      className="post-card-image"
                      style={{ backgroundImage: `url(${chapter.image})` }}
                    ></div>
                    <Card.Body className="chapterCardBody">
                      <div className="post-card-tags">
                        <span>{chapter.tags}</span>
                      </div>
                      <h2 className="post-card-title">{chapter.title}</h2>
                      <section className="post-card-excerpt">
                        {chapter.excerpt}
                      </section>
                      <div className="post-card-footer-left">
                        <div className="post-card-avatar">
                          <img
                            className="author-profile-image"
                            src={chapter.authorImage}
                            alt={chapter.authorName}
                          />
                        </div>
                        <span>{chapter.authorName}</span>
                      </div>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
            );
          })}
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Home;
